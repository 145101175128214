import React from 'react';
import './HomePageComponent.css';

const HomePageComponent = (props) => (
  <section className = "HomePage">
    <header>
      <img className = "SSALogo" src = "ssa-logo.png" alt = "SSA Logo"></img>
      <p className = "SSATitle">Sierra Space Agency</p>
    </header>
  </section>
)

export default HomePageComponent;
